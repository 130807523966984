/* eslint-disable react-hooks/exhaustive-deps */
import { differenceInYears } from "date-fns";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect } from "react";
import { useClientConfig } from "../../hooks/useClientConfig";
import { api } from "../../services/api";
import { useAppSelector } from "../../store";
import { ContainerComponent } from "../Container";

import {
  ContainerDataMobile,
  ContainerImageUser,
  ContainerInfoUserMobile,
  ContainerNamePatient,
  ContainerNamePatientMobile,
  StyledContainer,
} from "./style";
import "./styles.css";
import { UserInfosHeaderInterface } from "./types";

moment.locale("pt-br");

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UserInfosHeader = ({
  selectedButton,
  changeButton,
  userData,
}: UserInfosHeaderInterface) => {
  const userDefaultImage = require("../../assets/images/generic_avatar.png");
  const closeIcon = require("../../assets/images/close.png");

  const { doctorData, patientToShow } = useAppSelector((state) => state.user);

  const handleInsertClinicalDataView = async () => {
    try {
      await api.post("/audit/clinical/create", {
        date: new Date(),
        operation: "Clinical Data View",
        patient: {
          publicToken: patientToShow.mpiKey,
          name: patientToShow.nome,
        },
        process: "datamea",
        source: "Audit",
        user: doctorData?.name || "Not Informated",
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleInsertClinicalDataView();
  }, []);

  const style = `
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: fixed;
  `;

  const showModal = () => {
    document.getElementById("modal-more-details")?.setAttribute("style", style);
  };

  const hiddenModal = () => {
    document.getElementById("modal-more-details")?.removeAttribute("style");
  };

  const LocalizationLabel = (key: string, defaultValue: string) => {
    const { localization } = useClientConfig();
    if (localization && localization.some((value) => value.key === key))
      return localization.find((value) => value.key === key)?.value;
    else return defaultValue;
  };

  document.body.onresize = () => {
    if (document.body.clientWidth >= 810) hiddenModal();
  };

  const getSex = () => {
    if (!userData?.genero) return "Não Informado";

    switch (userData?.genero.toLowerCase().charAt(0)) {
      case "m":
        return "Masculino";
      case "f":
        return "Feminino";
      default:
        return "Outro";
    }
  };

  return (
    <>
      <div id="modal-more-details">
        <div className="more-details-content">
          <div className="close-modal-content">
            <img onClick={() => hiddenModal()} src={closeIcon} alt="Fechar" />
          </div>
          <ContainerDataMobile>
            <ContainerImageUser>
              <img src={userDefaultImage} alt="Usuário" />
            </ContainerImageUser>
            <ContainerNamePatientMobile>
              <h1>{userData?.nome}</h1>
              {userData?.["data-nascimento"] && (
                <>
                  <p>
                    {differenceInYears(
                      new Date(),
                      new Date(userData?.["data-nascimento"])
                    )}{" "}
                    Anos{" "}
                  </p>
                </>
              )}
              <p>
                <strong>Nome Social: </strong>
                {userData?.["nome-social"] ? (
                  <span style={{ color: "#000000" }}>
                    {userData?.["nome-social"]}
                  </span>
                ) : (
                  <span style={{ color: "#909090" }}>Não aplicado</span>
                )}
              </p>
              <p>
                <strong>Tipo Sanguíneo: </strong>
                {userData?.["tipo-sanquineo"] ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {userData?.["tipo-sanquineo"]}
                  </span>
                ) : (
                  <span style={{ color: "#909090" }}>Não aplicado</span>
                )}
              </p>
            </ContainerNamePatientMobile>
            <ContainerInfoUserMobile>
              <p>
                <strong>Idade: </strong>
                {userData?.["data-nascimento"] ? (
                  <span>
                    {userData?.["data-nascimento"] && (
                      <>
                        {differenceInYears(
                          new Date(),
                          new Date(userData?.["data-nascimento"])
                        )}{" "}
                        Anos{" "}
                      </>
                    )}
                    ({moment(userData?.["data-nascimento"]).format("L")})
                  </span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>Sexo: </strong>
                {userData?.genero ? (
                  <span>{getSex()}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>Raça/Cor: </strong>
                {userData?.["raca-cor-descricao"] ? (
                  <span>{userData?.["raca-cor-descricao"]}</span>
                ) : userData?.["raca-cor"] ? (
                  <span color="#c5c5c5">{userData?.["raca-cor"]}</span>
                ) : (
                  <span color="#c5c5c5">Não informado</span>
                )}
              </p>
              <p>
                <strong>Etnia: </strong>
                {userData?.["etnia"] ? (
                  <span>{userData?.["etnia"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>CNS: </strong>
                {userData?.["doc-cns"] ? (
                  <span>{userData?.["doc-cns"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>RG: </strong>
                {userData?.["doc-rg"] ? (
                  <span>{userData?.["doc-rg"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>CPF: </strong>
                {userData?.["doc-cpf"] ? (
                  <span>{userData?.["doc-cpf"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>Nome da Mãe: </strong>
                {userData?.["nome-mae"] ? (
                  <span>{userData?.["nome-mae"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>Nome do Pai: </strong>
                {userData?.["nome-pai"] ? (
                  <span>{userData?.["nome-pai"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>
                  {LocalizationLabel("prontuario", "Prontuário")}:{" "}
                </strong>
                {userData?.["id-origin"] ? (
                  <span>{userData?.["id-origin"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>Religião: </strong>
                {userData?.["religiao-descricao"] ? (
                  <span>{userData?.["religiao-descricao"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>Escolaridade: </strong>
                {userData?.["escolaridade"] ? (
                  <span>{userData?.["escolaridade"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>Profissão: </strong>
                {userData?.["profissao"] ? (
                  <span>{userData?.["profissao"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
              <p>
                <strong>Moradia: </strong>
                {userData?.["moradia"] ? (
                  <span>{userData?.["moradia"]}</span>
                ) : (
                  <span style={{ color: "#909090" }}>Não informado</span>
                )}
              </p>
            </ContainerInfoUserMobile>
          </ContainerDataMobile>
        </div>
      </div>

      <StyledContainer>
        <div className="patient-informations-area">
          <ContainerComponent>
            <div className="patient-informations-content">
              <figure className="patient-image-area">
                {userData?.foto ? (
                  <img
                    className="user-default-image"
                    src={userData.foto}
                    alt={`Foto de ${userData?.nome}`}
                  />
                ) : (
                  <img
                    className="user-default-image"
                    src={userDefaultImage}
                    alt="Usuário"
                  />
                )}
              </figure>
              <div className="patient-data-area">
                <ContainerNamePatient>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <h1 className="patient-username">{userData?.nome}</h1>
                  </div>
                  <p>
                    <strong>Nome Social: </strong>
                    {userData?.["nome-social"] ? (
                      <span style={{ color: "#000000" }}>
                        {userData?.["nome-social"]}
                      </span>
                    ) : (
                      <span style={{ color: "#909090" }}>Não aplicado</span>
                    )}
                  </p>
                  <p>
                    <strong>Tipo Sanguíneo: </strong>
                    {userData?.["tipo-sanquineo"] ? (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {userData?.["tipo-sanquineo"]}
                      </span>
                    ) : (
                      <span style={{ color: "#909090" }}>Não aplicado</span>
                    )}
                  </p>
                </ContainerNamePatient>
                <div className="patient-data-content">
                  <div>
                    <p>
                      <strong>Idade: </strong>
                      {userData?.["data-nascimento"] ? (
                        <span>
                          {userData?.["data-nascimento"] && (
                            <>
                              {differenceInYears(
                                new Date(),
                                new Date(userData?.["data-nascimento"])
                              )}{" "}
                              Anos{" "}
                            </>
                          )}
                          ({moment(userData?.["data-nascimento"]).format("L")})
                        </span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Sexo: </strong>
                      {userData?.genero ? (
                        <span>{getSex()}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Raça/Cor: </strong>
                      {userData?.["raca-cor-descricao"] ? (
                        <span>{userData?.["raca-cor-descricao"]}</span>
                      ) : userData?.["raca-cor"] ? (
                        <span color="#c5c5c5">{userData?.["raca-cor"]}</span>
                      ) : (
                        <span color="#c5c5c5">Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Etnia: </strong>
                      {userData?.["etnia"] ? (
                        <span>{userData?.["etnia"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>CNS: </strong>
                      {userData?.["doc-cns"] ? (
                        <span>{userData?.["doc-cns"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>RG: </strong>
                      {userData?.["doc-rg"] ? (
                        <span>{userData?.["doc-rg"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>CPF: </strong>
                      {userData?.["doc-cpf"] ? (
                        <span>{userData?.["doc-cpf"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Nome da Mãe: </strong>
                      {userData?.["nome-mae"] ? (
                        <span>{userData?.["nome-mae"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Nome do Pai: </strong>
                      {userData?.["nome-pai"] ? (
                        <span>{userData?.["nome-pai"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>
                        {LocalizationLabel("prontuario", "Prontuário")}:{" "}
                      </strong>
                      {userData?.["id-origin"] ? (
                        <span>{userData?.["id-origin"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>Religião: </strong>
                      {userData?.["religiao-descricao"] ? (
                        <span>{userData?.["religiao-descricao"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Escolaridade: </strong>
                      {userData?.["escolaridade"] ? (
                        <span>{userData?.["escolaridade"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Profissão: </strong>
                      {userData?.["profissao"] ? (
                        <span>{userData?.["profissao"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Moradia: </strong>
                      {userData?.["moradia"] ? (
                        <span>{userData?.["moradia"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                    <p>
                      <strong>Nacionalidade: </strong>
                      {userData?.["nacionalidade"] ? (
                        <span>{userData?.["nacionalidade"]}</span>
                      ) : (
                        <span style={{ color: "#909090" }}>Não informado</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="patient-more-details">
                  <span onClick={() => showModal()}>Mais detalhes</span>
                </div>
              </div>
            </div>
          </ContainerComponent>
        </div>
      </StyledContainer>
    </>
    // <StyledContainer>
    //   {/* Dados principais */}
    //   <div className="wrap-infos" style={{backgroundColor: "#ade"}}>
    //     <StyledPrimaryInfos>
    //       {/* foto paciente */}
    //       <div className="infos">
    //         <div className="details">
    //           <StyledImageContainer src={Mulher} alt="Foto paciente" />
    //           <div className="infos-subdiv">
    //             <h1>{userData?.nome}</h1>
    //             <div className="row-infos">
    //               {userData ? (
    //                 <>
    //                   <p>
    //                     {userData?.["data-nascimento"] && (
    //                       <>{differenceInYears(new Date(), new Date(userData?.["data-nascimento"]))} Anos</>
    //                     )}
    //                     ({moment(userData?.["data-nascimento"]).format("L")})
    //                   </p>
    //                   <p className="sex-text">{userData.genero === "M" ? "Masculino" : "Feminino"}</p>
    //                 </>
    //               ) : (
    //                 <Skeleton variant="text" width={200} />
    //               )}
    //             </div>
    //           </div>
    //         </div>

    //         {/* Botões de navegação */}
    //         <StyledButtonsContainer>
    //           <StyledButton
    //             disabled={!userData}
    //             onClick={() => handleChangePage({ page: "Sumario" })}
    //             selected={selectedButton === "Sumario"}
    //           >
    //             Sumário
    //           </StyledButton>
    //           <StyledButton
    //             disabled={!userData}
    //             onClick={() => handleChangePage({ page: "Linha do tempo" })}
    //             selected={selectedButton === "Linha do tempo"}
    //           >
    //             Linha do tempo
    //           </StyledButton>
    //         </StyledButtonsContainer>
    //       </div>
    //     </StyledPrimaryInfos>
    //     {/* Dados secundarios */}
    //     <StyledSecondaryInfos>
    //       {/* //TODO Criar caso que possa vir e n vir todos os dados */}
    //       {userData?.["id-origin"] && (
    //         <div className="data">
    //           {/* id-origin */}
    //           <p>Carteirinha</p>
    //           <p>{userData["id-origin"]}</p>
    //         </div>
    //       )}
    //       {userData?.["doc-cns"] && (
    //         <div className="data">
    //           {/* doc-cns */}
    //           <p>Cns</p>
    //           <p>{userData["doc-cns"]}</p>
    //         </div>
    //       )}
    //       {userData?.["doc-cpf"] && (
    //         <div className="data">
    //           <p>CPF</p>
    //           <p>{userData["doc-cpf"]}</p>
    //         </div>
    //       )}

    //       {userData?.["doc-rg"] && (
    //         <div className="data">
    //           <p>RG</p>
    //           <p>{userData["doc-rg"]}</p>
    //         </div>
    //       )}
    //       {userData?.["end-pais"] && (
    //         <div className="data">
    //           <p>Pais</p>
    //           <p>{userData["end-pais"]}</p>
    //         </div>
    //       )}
    //       {userData?.["end-estado"] && (
    //         <div className="data">
    //           <p>Estado</p>
    //           <p>{userData["end-estado"]}</p>
    //         </div>
    //       )}
    //       {/* //TODO adiconar cidade */}
    //     </StyledSecondaryInfos>
    //   </div>
    // </StyledContainer>
  );
};

export { UserInfosHeader };

