import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingPage } from "../../../pages/loading";
import { useAppDispatch, useAppSelector } from "../../../store";
import { openPopup } from "../../../store/slices/userAuthSlice";
import { IAuthResponse } from "../../../store/slices/userAuthSlice/interfaces";
import { userLoginThunk } from "../../../store/slices/userAuthSlice/thunks";
import { ClientsAuth } from "./interfaces";

export const AuthClient = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.user);

  const { client, key, patient, userid, username } = JSON.parse(
    sessionStorage.getItem("@Auth:client") as string
  );

  const clientThatIsAuth = async (clientAuth: ClientsAuth | undefined) => {
    let typeResponse = "";
    let detailsResponse: unknown = "";

    if (clientAuth === undefined) navigate("/server-error");
    else {
      if (clientAuth === "sabara" || clientAuth === "saocaetano") {
        if (key && patient) {
          const {
            payload: { type, message },
          } = (await dispatch(
            userLoginThunk({
              apiKey: key as string,
              authClient: {
                clientName: clientAuth,
                clientAuth: {
                  [clientAuth]: {
                    key,
                    patient,
                    userid,
                    username,
                  },
                },
              },
            })
          )) as { payload: IAuthResponse };

          typeResponse = type;
          detailsResponse = message;
        } else {
          dispatch(
            openPopup({
              title: "Dados de Autenticação Insuficientes",
              message:
                "Os dados para autenticação informados na URL são insuficientes. Por favor, certifique-se de estar passando todos os dados necessários na URL e tente novamente.",
            })
          );
          navigate("/");
        }
      }

      if (typeResponse === "authentication-error") {
        console.error(detailsResponse);
        navigate("/auth/login");
      }

      if (typeResponse === "unknown-error") {
        console.error(detailsResponse);
        navigate("/server-error");
      }

      if (typeResponse === "patient-not-found") {
        dispatch(
          openPopup({
            title: "Paciente não localizado",
            message: detailsResponse,
          })
        );
      }

      if (typeResponse === "invalid-api-key") {
        dispatch(
          openPopup({
            title: "Token de Autenticação inválido",
            message: detailsResponse,
          })
        );
      }

      if (typeResponse === "consentment-denied") {
        dispatch(
          openPopup({
            title: "Paciente não deu Consentimento!",
            message: detailsResponse,
          })
        );
      }

      navigate("/");
    }
  };

  useEffect(() => {
    clientThatIsAuth(client);
  }, [client]);

  return <>{loading ? <LoadingPage /> : ""}</>;
};
