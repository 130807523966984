import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components/loading";
import { useQuery } from "../../../hooks/useQuery";
import { useAppSelector } from "../../../store";
import { ClientsAuth } from "./interfaces";

export const RedirectAuth = () => {
  const navigate = useNavigate();

  const { loading } = useAppSelector((state) => state.user);
  const { client, code } = useParams<{ client: ClientsAuth; code: string }>();

  const query = useQuery();

  const clientThatIsAuth = async () => {
    const key = query.get("key");
    const patient = query.get("patient");
    const userid = query.get("userid");
    const username = query.get("username");

    const userRegisterNum = query.get("userRegisterNum");
    const userRegisterCouncil = query.get("userRegisterCouncil");
    const userRegisterUF = query.get("userRegisterUF");

    if (client && key && patient) {
      sessionStorage.setItem(
        "@Auth:client",
        JSON.stringify({
          client,
          key,
          patient,
          userid,
          username: username || "Não Informado",
          userRegisterNum,
          userRegisterCouncil,
          userRegisterUF,
        })
      );

      navigate(`/auth/client`);
    } else if (code) {
      sessionStorage.setItem("@Auth:code", JSON.stringify(code));
      navigate(`/auth/apikey`);
    } else navigate("/server-error");
  };

  useEffect(() => {
    clientThatIsAuth();
  }, [client, code]);

  return <>{loading && <Loading size="big" type="one" color="#558eff" />}</>;
};
